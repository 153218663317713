import React, { useState, useEffect } from "react";
import "./Header.css"; // Custom CSS file for the header

const Header = () => {
  const [isMobile, setIsMobile] = useState(false); // State to manage screen size (mobile vs desktop)
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to manage mobile menu visibility

  // Check screen size to toggle mobile menu
  const checkScreenSize = () => {
    setIsMobile(window.innerWidth <= 991); // Check if screen width is less than or equal to 991px (for mobile view)
  };

  // Add event listener on mount
  useEffect(() => {
    checkScreenSize(); // Check screen size on initial load
    window.addEventListener("resize", checkScreenSize); // Listen for window resize events

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  return (
    <header>
      <nav className="navbar navbar-light" style={{ background: "#f5ffff" }}>
        <div
          className="container"
          style={
            isMobile
              ? {}
              : {
                  display: "flex",
                  flexWrap: "nowrap",
                }
          }
        >
          {/* Logo */}
          <a className="navbar-brand" href="/">
            <img
              src="/assets/crop_logo.jpeg"
              style={{
                height: isMobile ? "50px" : "100px", // Adjust logo height
              }}
              alt="Logo"
            />
          </a>

          {/* Mobile Navigation Toggle Button */}
          {isMobile && (
            <button
              className="navbar-toggler"
              type="button"
              onClick={() => setIsMenuOpen(!isMenuOpen)} // Toggle menu visibility on click
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          )}

          {/* Navbar Links */}
          <div
            className={`navbar-collapse ${isMenuOpen ? "show" : ""}`}
            style={{
              display: isMobile && !isMenuOpen ? "none" : "block", // Hide menu on mobile if not open
            }}
          >
            <ul
              className="navbar-nav ml-auto"
              style={
                isMobile
                  ? {}
                  : {
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      paddingRight: "10%",
                    }
              }
            >
              <li className="nav-item">
                <a className="nav-link" href="/">
                  Home
                </a>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="/about">
                  About
                </a>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="/product">
                  Product
                </a>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="/contact">
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
