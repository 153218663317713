import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules"; // Import Swiper modules
import "swiper/css";
import "swiper/css/navigation";
import "./Slider.css";
import "animate.css";

const Slider = () => {
  const slides = [
    {
      src: "/assets/banner/b1.jpg",
      caption: "Trade International",
    },
    {
      src: "/assets/banner/b2.jpg",
      caption: "Explore Our Features!",
    },
    {
      src: "/assets/banner/b3.jpg",
      caption: "A Better Quality",
    },
  ];

  return (
    <Swiper
      modules={[Navigation, Autoplay]}
      navigation
      autoplay={{ delay: 3000 }}
      loop
      spaceBetween={30}
      slidesPerView={1}
      className="swiper-container"
    >
      {slides.map((slide, index) => (
        <SwiperSlide key={index} className="slide">
          <img
            src={slide.src}
            alt={`Slide ${index + 1}`}
            className="slide-img"
          />
          <div className="caption ">
            <h3 className={`caption-text animate__animated animate__fadeInUp`}>
              {slide.caption}
            </h3>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Slider;
