/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import Slider from "../components/Slider/Slider";

const features = [
  {
    src: "/assets/quality.png",
    title: "Metal Sorting",
    description: `We provide our clients the service of sorting metals and polymers according to their grades.`,
  },
  {
    src: "/assets/technical-support.png",
    title: "Metal Shredding & Grinding",
    description: `Our shredding and grinding services allow us to break down larger pieces of scrap metal into smaller, more manageable pieces.`,
  },
  {
    src: "/assets/capacity.png",
    title: "Washing Line",
    description: `We handle post-consumer and post-industrial plastics using our particularly created dual wash line, washing and drying..`,
  },
];

const Features = () => (
  <ul className="features-list">
    {features.map((feature, index) => (
      <li className="feature-item" key={index}>
        <div className="box-outline">
          <img src={feature.src} width="70" alt={feature.title} />
          <h5>{feature.title}</h5>
          <p>{feature.description}</p>
        </div>
      </li>
    ))}
  </ul>
);

const Home = () => {
  return (
    <div>
      <Slider />
      {/* <ProductMap /> */}
      <section
        style={{
          overflow: "hidden",
        }}
      >
        <div className="row">
          <div className="col-md-12">
            <div className="container">
              <h1 className="text-center mt-5 mb-5">About Us</h1>
              <img
                src="/assets/about.png"
                alt="About Us"
                className="img-fluid"
              />
            </div>
          </div>
          <div
            className="col-md-12 mt-5 my-5 py-5"
            style={{
              backgroundColor: "rgb(56, 65, 66,0.4)",
            }}
          >
            <div className="container">
              <h3
                style={{
                  color: "#164046",
                  textAlign: "left",
                }}
              >
                ADITYA Trading Company
              </h3>
              <h5
                style={{
                  color: "#164046",
                  textAlign: "left",
                }}
              >
                Import-Export
              </h5>
              <p className="text-justify">
                Aditya Trading Company is a leading exporter of a diverse range
                of high-quality products. Our product offerings include JINDAL
                CASTED, KILOSKAR SKULL RUNNER, JINDAL SKULL, JINDAL RUNNER,
                KILOSKAR BREAKING, SNETIFICAL PIPE, Press Cutting Welspun,
                Machinery Scrap, KILOSKAR SKULL CHIPS (8 to 40 mm), and Silicon
                Slag.
                <br />
                <br />
                GST NO: 24BZSPK1343L1Z2
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="container">
        {/* generate section */}
        <div className="row mb-5">
          <div className="col-md-12">
            <h1 className="text-center mt-5 mb-5">Core Values</h1>
          </div>
          <div className="col-md-12">
            <Features />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
