import React from "react";

const Footer = () => {
  const handleLinkClick = (event) => {
    event.preventDefault();
  };

  return (
    <footer className="page-foot bg-gray-dark">
      <div className="container section-top-70 section-bottom-50">
        <div className="row" style={{ justifyContent: "space-between" }}>
          <div className="col-sm-6 col-lg-4">
            <h6 className="page-footer-title">Contact Us</h6>
            <ul className="footer-list">
              <li>
                Phone Number: <a href="tel:+919998780888">+91 99987 80888</a>
              </li>
              <li>
                {/* <a href="mailto:sales@kalyanrai.com" onClick={handleLinkClick}>
                  sales@kalyanrai.com
                </a> */}
              </li>
              <li>
                <span
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  NAME – ADITYA TRADING CO
                </span>
                <br />
                ADD- AJI G.I.D.C OPP- PM DIESEL
                <br />
                80 FEET ROAD ,RAJKOT (GUJARAT)
                <br />
                GST NO- 24BZSPK1343L1Z2
                <br />
                Rajkot-360003, India
              </li>
            </ul>
          </div>
          <div className="col-sm-6 col-lg-4">
            <h6 className="page-footer-title">Important Links</h6>
            <ul className="footer-list">
              <li>
                <a href="/product">Product</a>
              </li>
              <li>
                <a href="/about">About Us</a>
              </li>
              <li>
                <a href="/contact">Contact Us</a>
              </li>
            </ul>
          </div>
          <div className="col-sm-6 col-lg-4" style={{
            display:"none"
          }} >
            <h6 className="page-footer-title">About Us</h6>
            <ul className="footer-list">
              <li>
                <a
                  href="about.php"
                  onClick={handleLinkClick}
                  className="tooltip-link"
                >
                  Information
                </a>
              </li>
              <li>
                <a
                  href="about.php"
                  onClick={handleLinkClick}
                  className="tooltip-link"
                >
                  Statistics
                </a>
              </li>
              <li>
                <a
                  href="about.php"
                  onClick={handleLinkClick}
                  className="tooltip-link"
                >
                  Vision & Mission
                </a>
              </li>
            </ul>
          </div>
          {/* <div className="col-sm-6 col-lg-3">
            <h6 className="page-footer-title">Social Media</h6>
            <p>Feel free to contact our department.</p>
            <a className="call-button" href="tel:07043654111">
              <span className="call-icon"></span> Make a Call Now
            </a>
          </div> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
