import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Home from "./pages/Home";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import Footer from "./components/Footer";
import Product from "./pages/Product/Product";
import "./App.css";
import Loader from "./components/Loader/Loader";
import GoUpButton from "./components/GoUpButton/GoUpButton"; // Import the Go Up button

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fallback to remove loader after a timeout
    const timer = setTimeout(() => setLoading(false), 1500); // Adjust time as needed
    const handlePageLoad = () => {
      setLoading(false);
      clearTimeout(timer);
    };

    window.addEventListener("DOMContentLoaded", handlePageLoad);

    return () => {
      window.removeEventListener("DOMContentLoaded", handlePageLoad);
    };
  }, []);

  return (
    <Router>
      <div className="App">
        {loading ? (
          <Loader />
        ) : (
          <div className="">
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/product" element={<Product />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
            <Footer />
            <GoUpButton /> {/* Add the Go Up button here */}
          </div>
        )}
      </div>
    </Router>
  );
}

export default App;
