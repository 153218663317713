// generate about page
import React from "react";
import "./product.css";

const products = [
  {
    title: "JINDAL CASTED",
    image: "1.jpeg",
    description: "Agri Products",
    pdfLink: "/37-Agri Product Brochure.pdf",
  },
  {
    title: "KILOSKAR SKULL RUNNER",
    image: "File_9.jpeg",
    description: "Indian Spices",
    pdfLink: "/99-sPICES.pdf",
  },
  {
    title: "JINDAL SKULL",
    image: "File_10.jpeg",
    description: "All Type of Namkeen and Snacks",
    pdfLink: "/38-Namkeen & Snacks.pdf",
  },
  {
    title: "JINDAL RUNNER",
    image: "File_1.jpeg",
    description: "All types of Hardware and Sanitaryware Products",
    pdfLink: "/69-Hardware.pdf",
  },
  {
    title: "KILOSKAR BREAKING",
    image: "File_2.jpeg",
    description: "Kitchen Ware Products",
    pdfLink: "/72-Kitchen Ware.pdf",
  },
  {
    title: "SNETIFICAL PIPE",
    image: "File_3.jpeg",
    description: "Kitchen Ware Products",
    pdfLink: "/72-Kitchen Ware.pdf",
  },
  {
    title: "Press cutting welspun",
    image: "File_4.jpeg",
    description: "Kitchen Ware Products",
    pdfLink: "/72-Kitchen Ware.pdf",
  },
  {
    title: "Machinery scrap",
    image: "File_5.jpeg",
    description: "Kitchen Ware Products",
    pdfLink: "/72-Kitchen Ware.pdf",
  },
  {
    title: "Kiloskar skull chips 8 to 40 mm",
    image: "File_6.jpeg",
    description: "Kitchen Ware Products",
    pdfLink: "/72-Kitchen Ware.pdf",
  },
  {
    title: "Silicon slag",
    image: "File_7.jpeg",
    description: "Kitchen Ware Products",
    pdfLink: "/72-Kitchen Ware.pdf",
  },
  {
    title: "DI PIPE JINDAL",
    image: "File_12.jpeg",
    description: "Kitchen Ware Products",
    pdfLink: "/72-Kitchen Ware.pdf",
  },
  {
    title: "Ci disk break",
    image: "File_11.jpeg",
    description: "Kitchen Ware Products",
    pdfLink: "/72-Kitchen Ware.pdf",
  },
  {
    title: "MS SCRAP",
    image: "File_13.jpeg",
    description: "Kitchen Ware Products",
    pdfLink: "/72-Kitchen Ware.pdf",
  },
  {
    title: "CRC BANDAL SCRAP MS",
    image: "File_14.jpeg",
    description: "Kitchen Ware Products",
    pdfLink: "/72-Kitchen Ware.pdf",
  },
  {
    title: "Di skull",
    image: "File_15.jpeg",
    description: "Kitchen Ware Products",
    pdfLink: "/72-Kitchen Ware.pdf",
  },
];

const ProductCard = ({ title, image, description, pdfLink }) => (
  <div className="col-md-6 col-lg-4 mb-4">
    <article className="post-modern">
      <div className="post-header">
        <h5>{title}</h5>
      </div>
      <div className="post-body">
        <figure>
          <img
            src={"/assets/product/" + image}
            alt={title}
            width="570"
            height="321"
          />
        </figure>
        <div className="post-inset">{/* <p>{description}</p> */}</div>
      </div>
      {/* <div className="post-footer">
        <div className="post-meta">
          <ul className="post-list">
            <li className="object-inline">
              <a href={pdfLink} download>
                <span className="icon icon-xxs icon-primary material-icons"></span>
                Download Pdf
              </a>
            </li>
          </ul>
        </div>
      </div> */}
    </article>
  </div>
);

export const ProductMap = () => {
  return (
    <div className={`container mt-5`}>
      <div className="row row-30 justify-content-md-center justify-content-lg-start space-30 space-bottom">
        {products.map((product, index) => (
          <ProductCard
            key={index}
            title={product.title}
            image={product.image}
            description={product.description}
            pdfLink={product.pdfLink}
          />
        ))}
      </div>
    </div>
  );
};

const Product = () => {
  return (
    <>
      <section className="section-30 section-md-40 section-lg-66 section-xl-top-100 section-xl-bottom-120 bg-gray-dark page-title-wrap about-hero">
        <div className="overlay"></div>
        <div className="container">
          <div className="page-title">
            <h2>Our Products</h2>
            <ol className="breadcrumb-custom">
              <li>
                <a href="index.php">Home</a>
              </li>
              <li>Products</li>
            </ol>
          </div>
        </div>
      </section>
      <ProductMap />
    </>
  );
};

export default Product;
