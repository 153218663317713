import React, { useState, useEffect } from "react";
import "animate.css"; // Import animate.css for animation
import "./contact.css"; // Import the styles
import emailjs from "@emailjs/browser";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isRateLimited, setIsRateLimited] = useState(false);

  useEffect(() => {
    // Check if the user is rate-limited
    const lastSubmittedTime = localStorage.getItem("lastSubmittedTime");
    if (lastSubmittedTime) {
      const timePassed = Date.now() - parseInt(lastSubmittedTime, 10);
      if (timePassed < 20 * 60 * 1000) {
        setIsRateLimited(true);
      }
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate form
    let errors = { name: "", email: "", message: "" };
    if (!formData.name) errors.name = "Name is required";
    if (!formData.email) errors.email = "Email is required";
    if (!formData.message) errors.message = "Message is required";
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formData.email)) {
      errors.email = "Email is invalid";
    }

    if (errors.name || errors.email || errors.message) {
      setFormErrors(errors);
    } else {
      setFormErrors({});

      // Check if rate limit is active
      const lastSubmittedTime = localStorage.getItem("lastSubmittedTime");
      if (lastSubmittedTime) {
        const timePassed = Date.now() - parseInt(lastSubmittedTime, 10);
        if (timePassed < 20 * 60 * 1000) {
          setIsRateLimited(true);
          return;
        }
      }
      if (isRateLimited) {
        setFormSubmitted(true); // Show thank you message
        setFormData({ name: "", email: "", message: "" }); // Clear form
        // localStorage.setItem("lastSubmittedTime", Date.now().toString());
        // setIsRateLimited(false);
        return;
      }

      // Send the email and update rate limit
      emailjs
        .send("service_1we4moc", "template_8p9ch4i", {
          from_name: formData.name,
          to_name: formData.email,
          message: formData.message,
          reply_to: "contact@adityatradingcompany.com",
        })
        .then(() => {
          setFormSubmitted(true); // Show thank you message
          setFormData({ name: "", email: "", message: "" }); // Clear form
          localStorage.setItem("lastSubmittedTime", Date.now().toString());
          setIsRateLimited(false);
        });
    }
  };

  return (
    <div className="contact-page">
      {/* Contact Form Section */}
      <section className="section-50 section-md-75 bg-gray-light">
        <div className="container">
          <h3>Contact Form</h3>
          <p
            style={{
              display: formSubmitted ? "none" : "block",
            }}
          >
            Please fill out the form below, and we will get back to you as soon
            as possible.
          </p>
          {formSubmitted && (
            <div className="thank-you-message animate__animated animate__fadeIn">
              <h4>
                Thank you for contacting us! We will get back to you soon.
              </h4>
            </div>
          )}
          {
            <form
              onSubmit={handleSubmit}
              style={{
                display: formSubmitted ? "none" : "block",
              }}
            >
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Name</label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Your Name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                    {formErrors.name && (
                      <small className="form-error">{formErrors.name}</small>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Your Email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {formErrors.email && (
                      <small className="form-error">{formErrors.email}</small>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>Message</label>
                <textarea
                  name="message"
                  className="form-control"
                  rows="4"
                  placeholder="Your Message"
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
                {formErrors.message && (
                  <small className="form-error">{formErrors.message}</small>
                )}
              </div>
              <button type="submit" className="btn btn-primary">
                Send Message
              </button>
            </form>
          }
        </div>
      </section>
    </div>
  );
};

export default ContactUs;
