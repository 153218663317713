// generate about page
import React from "react";
import "./about.css";

const About = () => {
  return (
    <>
      <section className="section-30 section-md-40 section-lg-66 section-xl-top-100 section-xl-bottom-120 bg-gray-dark page-title-wrap about-hero">
        <div className="overlay"></div>
        <div className="container">
          <div className="page-title">
            <h2>About </h2>
            <ol className="breadcrumb-custom">
              <li>
                <a href="index.php">Home</a>
              </li>
              <li>About Us</li>
            </ol>
          </div>
        </div>
      </section>
      <section className="section-50 section-md-75 section-xl-100 bg-default about-us-section">
        <div className="container">
          <div className="row ">
            <div className="col-md-6 col-lg-6">
              <div className="thumbnail thumbnail-profile">
                <div className="thumbnail-image">
                  <img
                    src="/assets/about.png"
                    alt="About Us"
                    width="570"
                    height="570"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 offset-xl-1 col-xl-5 text-md-left">
              <h4>Know About Us</h4>
              <p className="text-spacing-50 text-gray">
                Aditya Trading Company is a leading exporter of a diverse range
                of high-quality products. Our product offerings include{" "}
                <strong>JINDAL CASTED</strong>,{" "}
                <strong>KILOSKAR SKULL RUNNER</strong>,{" "}
                <strong>JINDAL SKULL</strong>, <strong>JINDAL RUNNER</strong>,{" "}
                <strong>KILOSKAR BREAKING</strong>,{" "}
                <strong>SNETIFICAL PIPE</strong>,{" "}
                <strong>Press Cutting Welspun</strong>,{" "}
                <strong>Machinery Scrap</strong>,{" "}
                <strong>KILOSKAR SKULL CHIPS (8 to 40 mm)</strong>, and{" "}
                <strong>Silicon Slag</strong>.
                <br />
                <br />
                GST NO: 24BZSPK1343L1Z2
              </p>
              <p className="text-spacing-50 text-gray">
                At Aditya Trading Company, we take pride in offering top-quality
                products sourced from trusted suppliers and manufacturers. Our
                dedicated team ensures that every product, industrial materials like{" "}
                <strong>Silicon Slag</strong> and{" "}
                <strong>Machinery Scrap</strong>, is thoroughly inspected for
                quality and reliability. We specialize in providing tailored
                solutions to meet the unique requirements of our customers,
                ensuring that our products are delivered on time and at
                competitive prices.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
